import quote1 from "../images/quotes/quotes1.png";
import quote2 from "../images/quotes/quotes2.png";
import quote3 from "../images/quotes/quotes3.png";
import quote4 from "../images/quotes/quotes4.png";
import quote5 from "../images/quotes/quotes5.png";
import React, {useState} from "react";

const QuoteGallery = () => {

    const [quoteNum, setQuoteNum] = useState(0);

    const quotes = [
        quote1, quote2, quote3, quote4, quote5
    ];

    const handlePrev = () => {
        document.getElementById("Next").disabled = false;
        if(quoteNum !== 0){
            setQuoteNum(quoteNum -1);
        }
        else {
            document.getElementById("Prev").disabled = true;
        }
    }

    const handleNext = () => {
        document.getElementById("Prev").disabled = false;
        if(quoteNum !== quotes.length - 1){
            setQuoteNum(quoteNum +1);
        }
        else {
            document.getElementById("Next").disabled = true;
        }
    }

    window.onload = () => {
        document.getElementById("Prev").disabled = true;
    }


    return(
        <div className="Quote-gallery">
            <img src={quotes[quoteNum]} alt="quote" className="Active-quote" />
            <div className="Gallery-nav">
                <button className="Gallery-butn" id="Prev" onClick={handlePrev}>Prev</button>
                <p className="Quote-num">{quoteNum + 1} / {quotes.length}</p>
                <button className="Gallery-butn" id="Next" onClick={handleNext}>Next</button>
            </div>
        </div>
    )
}

export default QuoteGallery;